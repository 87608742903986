import React, { useState } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BackgroundSvg from "./BackgroundSvg";
import BDMImg from '../Assests/Team/BDM.jpg';
import Mooses from '../Assests/Team/mooses.JPG';
import SPRImg from "../Assests/Team/Director-SPR.jpg";
import DEPImg from '../Assests/Team/Director-Event-Prod.jpg';
import founderIMg from '../Assests/Team/Founder.jpeg'
import MDImg from '../Assests/Team/MD.jpeg'


const members = [
  {
      img: founderIMg,
      name: "Mr.Alfred Selavaraj",
      title: "Chairman & Director",
      desc: "Picture Productions – An Indian company Incorporated in 1991 and led by its founder and Director, Mr. Alfred Selvaraj, Picture Productions is a legendary professional company offering unparalleled sound, lighting, trussing, and video solutions. With over three decades of experience, we specialize in delivering exceptional technical services for a wide range of events, including concerts, music festivals, television and awards shows, corporate events, large-scale public events, sporting events, religious events, fashion shows, conferences, and exhibitions. Our commitment to excellence and innovation ensures every event we handle is a memorable and impactful experience.",
      id: 1,
      },
      {
      img: MDImg,
      name: "Sathish Babu",
      title: "Founder & Managing Director ",
      id: 2,
      desc: "Ranga Electricals is a leading name in the power generator rental and hiring industry in Tamil Nadu, headed by Mr. Sathish Kumar is renowned for its reliable and comprehensive solutions for all your power needs. We offer a wide range of generators, from 5 K.V.A to 1000 K.V.A, catering to various sectors including small to large-scale industries, telecom, hospitality, banking, entertainment, weddings, exhibitions, film, IT parks, and shipping. We provide brand new generators for long-term projects, ensuring ambitious standards of service at competitive rates. Your Trusted Partner for Power Generator Rentals and Hiring in Tamil Nadu ",
      },
      {
      img: Mooses,
      name: "Mr.Moses",
      title: "Founder & CEO",
      id: 3,
      desc: "Pixel Art Colours, we strive to provide a seamless and exceptional renting experience. Under the expert leadership of Mr. Moses, we combine technical brilliance with outstanding customer service to ensure you receive exactly, all you need is his Personalized Guidance and Expertise. Our technical experts will collaborate closely with you to select the perfect products tailored to your requirements. We offer a diverse selection of items to suit every individual and budget, ensuring a customized experience for all our clients. Our extensive range of products is designed to meet your every need, our commitment is to make the difference with Pixel Art Colours – where renting is made easy, personalized, and visually captivating.",
      },
      {
      img: DEPImg,
      name: "Baskar",
      title: "Director – Event Production",
      id: 4,
      desc: "Baskar brings in about 20 years of experience and is highly specialized of event production and event management. He brings a unique perspective to every project. Excelling in challenging roles, he is committed to delivering exceptional content and flawlessly executing events. As a strong leader Seeking to drive this consortium to project success as a Director of Events, he aims to create memorable and impactful experiences through meticulous planning and innovation on the future events live concerts, music launches, conferences, exhibitions, and shows.",
      },
      {
      img: SPRImg,
      name: "Sharanya L Sharma",
      title: "Director - Strategies & PR",
      id: 5,
      desc: "Sharanya L. Sharma exemplifies the qualities of a top-tier strategist with 25+ years if experience a visionary and a Public Relation Maestro professional with indomitable Determination. Her strength, determination, and vibrant leadership make her an indispensable leader in our consortium. As she continues to drive forward our strategies and public relations efforts, we are confident that her contributions will propel us to new heights of success and innovation",
      },
      {
      img: BDMImg,
      name: "Prassana",
      title: "Business Development Manager",
      id: 6,
      desc: "Prassana is Dedicated and highly skilled Media Production Professional with over 7 years of experience in the Media Network landscape. Passionate about cinema and media, he brings a unique perspective and thrives in challenging roles in Production Houses, Media Houses, OTT Platforms, or Channels. Committed to delivering exceptional content and driving project success as a Production Head, Executive Producer, Project Head, or Show Director.",
      },
];

const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow service-prev ${className}`}>
        <AiOutlineArrowLeft className="arrows" style={{ color: "white" }} />
      </div>
    );
  };
  
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div onClick={onClick} className={`arrow service-next ${className}`}>
        <AiOutlineArrowRight className="arrows" style={{ color: "white" }} />
      </div>
    );
  };
  

const Team = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const openModal = (content) => {
    setModalContent(content);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setModalContent({});
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    className: "slides",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="relative md:flex items-center overflow-hidden" id="team-section">
        <BackgroundSvg/>
      <div className="max-w-[1240px] mx-auto py-14 relative">
        <h2 className="text-2xl md:text-4xl font-bold mb-6 text-center">
          Meet the faces behind <span className="text-[#1842b6]">The ONE</span>
        </h2>
        
        <Slider {...settings}>
          {members.map((member, index) => (
            <div key={index} className="p-7 py-9">
              <div className="h-[480px] text-center rounded-2xl hover:scale-105 duration-300">
                <div className="md:flex flex-col justify-evenly items-center h-full z-10">
                  {member.img && (
                    <div
                      className="team-profile-blob"
                      style={{ backgroundImage: `url(${member.img})` }}
                    ></div>
                  )}
                  <p className="text-2xl font-semibold text-orange-600">{member.name}</p>
                  <p className="text-xl text-[#25256a]">{member.title}</p>
                  <button onClick={() => openModal(member)} className="rounded-xl relative p-2 cursor-pointer md:flex items-center bg-[#1842b6] text-white group hover:bg-[#1842f9]">Learn More</button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Project Description"
          className="modal"
          overlayClassName="modal-overlay"
        >
          <div className="relative overflow-hidden rounded-md shadow-md flex flex-col justify-center items-center">
              <BackgroundSvg className="absolute  inset-0 w-full h-full" />
            <div className="relative z-10 flex flex-col items-center p-4">
              <img src={modalContent.img} alt={modalContent.name} className="h-[250px] rounded-full w-[250px]" />
              <h2 className="text-2xl font-bold mb-4">{modalContent.title}</h2>
              <p className="text-md text-center text-gray-600 mb-4">{modalContent.desc}</p>
              <button
                className="p-2 border bg-[#1842b6] rounded-md text-white text-sm"
                onClick={closeModal}
              >
                Close
              </button>
              </div>
          </div>
        </Modal>
      </div>
    </section>
  );
};

export default Team;
