import React from "react";
import { IoMailOpen, IoCall, IoLocationSharp } from "react-icons/io5";

const Footer = () => {
  return (
        <div className="bg-[#1842b6] h-min-screen" id="contact-section">
          <div class="max-w-[1300px] mx-auto py-4 grid grid-cols-1 gap-3 lg:grid-cols-4">
            <div className="px-10">
              <div class="flex justify-center text-orange-600 text-xl  sm:justify-start">
                Ovation Night Events
              </div>

              <p class="mt-4 max-w-md text-center text-xl text-[#9aca3c] sm:max-w-xs sm:text-left">
                Crafting Legendary Events Across the Globe
              </p>
            </div>
          <div className="">
                <IoMailOpen size={30} className="text-white" />
                <a  href="mailto: info@onecompany.com" className=" text-xl text-white flex flex-col justify-center mt-4">
                  info@onecompany.com
                </a>
            </div>
            <div className="">
                <IoCall size={30} className="text-white" />
                <a class=" text-xl text-white flex flex-col justify-center mt-4" href="tel:+91-9962759972">
                  +91-99627 59972 <br />+91-99430 64000
                </a>
            </div>
            <div className="">
                <IoLocationSharp size={30} className=" text-white" />
                <a class=" text-xl text-white flex flex-col justify-center mt-4">
                  Egmore, Chennai, India
                </a>    
            </div>
              
            </div>
          <div class="mt-2 p-2 bg-black bg-opacity-40">
            <div class="flex flex-wrap items-center justify-center text-white text-sm"> 
              <p className="text-[#9aca3c]">&copy; THE ONE 2024.</p>
              <p className="ml-2">All rights reserved.</p>
              <p className="ml-2">Designed by <a href="https://namuvi.com/" className="underline text-orange-600">Namuvi Technologies</a></p>
            </div>
  </div>
</div>

  );
};
export default Footer;
