import React from "react";

const BgSvg2 = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlnsxlink="http://www.w3.org/1999/xlink"
      xmlnssvgjs="http://svgjs.dev/svgjs"
      viewBox="0 0 800 450"
         preserveAspectRatio="none"
      className="absolute inset-0 w-full h-full"
    >
      <defs>
        <filter
          id="bbblurry-filter"
          x="-100%"
          y="-100%"
          width="400%"
          height="400%"
          filterUnits="objectBoundingBox"
          primitiveUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feGaussianBlur
            stdDeviation="68"
            x="0%"
            y="0%"
            width="100%"
            height="100%"
            in="SourceGraphic"
            edgeMode="none"
            result="blur"
          ></feGaussianBlur>
        </filter>
      </defs>
      <g filter="url(#bbblurry-filter)">
        <ellipse
          rx="150"
          ry="150"
          cx="112.832652698863626"
          cy="20.20847112482244"
          fill="#9aca3c"
        ></ellipse>
        <ellipse
          rx="150"
          ry="150"
          cx="682.2607699307529"
          cy="480.5042780095881"
          fill="#6a6ba1"
        ></ellipse>
      </g>
    </svg>
  );
};
export default BgSvg2;
