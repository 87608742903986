import { motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import BgSvg2 from "./BgSvg2";
import img1 from "../Assests/concert-crowd-on-a-music-concert-2023-11-27-05-00-26-utc.jpg"
import img2 from "../Assests/crowd-at-a-concert-2023-11-27-05-34-15-utc.jpg"
import img3 from "../Assests/crowd-at-concert-2023-11-27-05-13-30-utc.jpg"
import img4 from "../Assests/crowd-partying-stage-lights-live-concert-summer-mu-2023-11-27-04-52-51-utc.jpg"
import img5 from "../Assests/youth-at-concert-2023-11-27-05-03-06-utc.jpg"
import img6 from "../Assests/Gallery/digital-mixing-console-sound-mixer-control-panel-closeup-audio-faders.jpg"
import img7 from "../Assests/Gallery/lots-led-lightning-systems-few-with-color-filters-stairs-movie-set.jpg"
import img8 from "../Assests/Gallery/view-black-white-theatre-stage.jpg"
import img9 from "../Assests/Gallery/theme-based.jpg"
import img10 from "../Assests/Gallery/nightlife-with-people-dancing-club.jpg"
import img11 from "../Assests/Gallery/professional-lighting-equipment-movie-set-with-smoke-air.jpg"
import img12 from "../Assests/Gallery/rock-band-silhouettes-stage-concert.jpg"
import img13 from "../Assests/Gallery/20191226_152946.jpg"
import img14 from "../Assests/Gallery/20220216_175045.jpg"
import img15 from "../Assests/Gallery/20220504_124850.jpg"
import img16 from "../Assests/Gallery/20230504_180153.jpg"


const Gallery = () => {
  return (
    
    <motion.section className="relative min-h-screen flex items-center overflow-hidden">
      <BgSvg2/>
      <div className="relative h-screen px-8 grid grid-cols-1 md:grid-cols-2 items-center gap-1 mx-auto">
      
      <div>
       
       <h3 className="text-4xl md:text-7xl font-semibold text-[#1842b6]">Event Elegance</h3>
        <p className="font-bold md:text-xl text-slate-900 my-4 md:my-6">
         "Showcasing Our Premier Event Management Expertise"
        </p>
      </div>
      <ShuffleGrid />
    </div>
    </motion.section>
  );
};

const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex != 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

const squareData = [
  {
    id: 1,
    src: img1,
  },
  {
    id: 2,
    src: img2,
  },
  {
    id: 3,
    src: img3,
  },
  {
    id: 4,
    src: img4,
  },
  {
    id: 5,
    src: img5,
  },
  {
    id: 6,
    src: img6,
  },
  {
    id: 7,
    src: img7,
  },
  {
    id: 8,
    src: img8,
  },
  {
    id: 9,
    src: img9,
  },
  {
    id: 10,
    src: img10,
  },
  {
    id: 11,
    src: img11,
  },
  {
    id: 12,
    src: img12,
  },
  {
    id: 13,
    src: img13,
  },
  {
    id: 14,
    src: img14,
  },
  {
    id: 15,
    src: img15,
  },
  {
    id: 16,
    src: img16,
  },

];

const generateSquares = () => {
  return shuffle(squareData).map((sq) => (
    <motion.div
      key={sq.id}
      layout
      transition={{ duration: 1.5, type: "spring" }}
      className="w-full h-full"
      style={{
        backgroundImage: `url(${sq.src})`,
        backgroundSize: "cover",
      }}
    ></motion.div>
  ));
};

const ShuffleGrid = () => {
  const timeoutRef = useRef(null);
  const [squares, setSquares] = useState(generateSquares());

  useEffect(() => {
    shuffleSquares();

    return () => clearTimeout(timeoutRef.current);
  }, []);

  const shuffleSquares = () => {
    setSquares(generateSquares());

    timeoutRef.current = setTimeout(shuffleSquares, 3000);
  };

  return (
    <div className="grid grid-cols-4 grid-rows-4 h-[350px] w-[300px] md:h-[500px] md:w-[650px] gap-1">
      {squares.map((sq) => sq)}
    </div>
  );
};

export default Gallery;