import React from 'react';
import Header from './Components/Header';
import Home from './Components/Home';
import Footer from './Components/Footer';
import About from './Components/About';
import Team from './Components/Team';
import Services from './Components/Services';
import WhyChooseUs from './Components/WhyChooseUs';
import Gallery from './Components/Gallery';

function App() {
  return (
    <div className='overflow-x-hidden'>
      <Header/>
      <Home/>
      <About/>
      <Team/>
      <Services/>
      <WhyChooseUs/>
      <Gallery/>
      <Footer/>
    </div>
  );
}

export default App;

