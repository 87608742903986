import React from 'react';

const BackgroundSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlnsSvgjs="http://svgjs.dev/svgjs"
    viewBox="0 0 800 450"
    className="absolute inset-0 w-full h-full"
    preserveAspectRatio="none"
  >
    <defs>
      <filter
        id="bbblurry-filter"
        x="-100%"
        y="-100%"
        width="400%"
        height="400%"
        filterUnits="objectBoundingBox"
        primitiveUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feGaussianBlur
          stdDeviation="68"
          x="0%"
          y="0%"
          width="100%"
          height="100%"
          in="SourceGraphic"
          edgeMode="none"
          result="blur"
        ></feGaussianBlur>
      </filter>
    </defs>
    <g filter="url(#bbblurry-filter)">
      <ellipse
        rx="150"
        ry="150"
        cx="112.07644375887782"
        cy="475.97335537997157"
        fill="#9aca3c"
      ></ellipse>
      <ellipse
        rx="150"
        ry="150"
        cx="679.8062244762074"
        cy="10.777020541104434"
        fill="#6a6ba1"
      ></ellipse>
    </g>
  </svg>
);

export default BackgroundSvg;
