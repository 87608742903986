import React from "react";
import { motion } from "framer-motion";
import { FaPeopleGroup, FaGlobe, FaServicestack } from "react-icons/fa6";
import { TfiLayoutListThumbAlt } from "react-icons/tfi";
import { fadeIn } from './Variants';
import BackgroundSvg from "./BackgroundSvg";
import OneLogo from '../Assests/hero-img.png'



const WhyChooseUs = () => {
  return (
    <motion.section className="relative flex items-center overflow-hidden">
        <BackgroundSvg />
<div className=" py-12 z-10">
  <div className="">
    <h1 className="text-3xl font-bold text-center sm:text-4xl p-2">
      Why choose Us?
    </h1>
    <div className="flex justify-between items-center w-full">
    <div className="md:w-[50%] md:ml-12 ">
          <ul className="mt-2 max-w-xl space-y-6 leading-7 lg:max-w-none">
            <motion.li
              className="relative md:pl-6"
              variants={fadeIn('up', 0.3)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.5 }}
            >
              <div className="flex font-bold text-[#1842b6]">
                <FaServicestack size={30} />
                <p className="ml-2 text-xl">Experience & Expertise</p>
              </div>
              <p className="font-semibold text-gray-600 text-lg">
                With years of experience in the event industry, our team possesses the expertise needed to manage events of any scale. Our history of successful events in Chennai and beyond speaks for itself.
              </p>
            </motion.li>
            <motion.li
              className="relative md:pl-6"
              variants={fadeIn('up', 0.4)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.5}}
            >
              <div className="flex font-bold text-[#1842b6]">
                <TfiLayoutListThumbAlt size={30} />
                <p className="ml-2 text-xl">Attention to Detail</p>
              </div>
              <p className="font-semibold text-gray-600 text-lg">
                We believe that the magic lies in the details. Our meticulous approach ensures that every element of your event is perfectly executed, leaving no room for errors.
              </p>
            </motion.li>
            <motion.li
              className="relative md:pl-6"
              variants={fadeIn('up', 0.5)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.5}}
            >
              <div className="flex font-bold text-[#1842b6]">
                <FaGlobe size={30} />
                <p className="ml-2 text-xl">Global Reach</p>
              </div>
              <p className="font-semibold text-gray-600 text-lg">
                While we are rooted in Chennai, our services extend globally. We have the capability to manage events anywhere in the world, bringing the same level of passion and precision to every project.
              </p>
            </motion.li>
            <motion.li
              className="relative md:pl-6"
              variants={fadeIn('up', 0.6)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: false, amount: 0.5}}
            >
              <div className="flex font-bold text-[#1842b6]">
                <FaPeopleGroup size={30} />
                <p className="ml-2 text-xl">Client-Centric Approach</p>
              </div>
              <p className="font-semibold text-gray-600 text-lg">
                Your vision is our mission. We work closely with you to understand your requirements and deliver an event that exceeds your expectations.
              </p>
            </motion.li>
          </ul>
        </div>
        <div className="hidden md:block">
        <img
          src={OneLogo}
          alt="aboutus"
          className="h-[650px] w-[700px]"
        />
      </div>
    </div>
        

      
    </div>
  </div>

    </motion.section>
  );
};

export default WhyChooseUs;
