import React from "react";
import { motion } from "framer-motion";
import BgSvg2 from "./BgSvg2";
import stgeImg from "../Assests/Gallery/20191226_152946.jpg";
import lightsImg from "../Assests/Gallery/lightshow-2223124_640.jpg";
import customSet from "../Assests/Gallery/20220504_124850.jpg";
import powerImg from "../Assests/Gallery/digital-mixing-console-sound-mixer-control-panel-closeup-audio-faders.jpg"
import ledImg from "../Assests/Gallery/—Pngtree—colored multi colored optical beam_15528848.png"
import themeImg from "../Assests/Gallery/theme-based.jpg"

const servicedata = [
    {
      img: stgeImg,
      title: 'Truss & Stage Design',
      description: 'We create sturdy and innovative truss and stage setups that form the backbone of your event'
    },
    {
      img: lightsImg,
      title: 'Lighting & Sound',
      description: 'Our advanced lighting and sound systems ensure that your event not only looks spectacular but also sounds perfect'
    },
    {
      img: customSet,
      title: 'Customized Set Designs',
      description: "Unique and tailored set designs that resonate with your event's narrative"
    },
    {
        img: powerImg,
        title: 'Power Solutions',
        description: 'Reliable power management is crucial, and we provide seamless power solutions to keep your event running smoothly'
      },
      {
        img: ledImg,
        title: 'LED Displays',
        description: 'High-quality LED screens that enhance the visual appeal of your event, making every moment memorable'
      },
      {
        img: themeImg,
        title: 'Theme-based Production',
        description: "Whether it's a corporate event, wedding, or a music concert, we bring your vision to life with our innovative theme-based production"
      },

  ];

const Services = () => {
  return (
    <motion.section className="relative items-center overflow-hidden" id="services-section">
        <BgSvg2 />
    <div className="z-10 p-4">
      <div className="max-w-[1300px] mx-auto text-center">
        <h1 className="text-4xl font-bold">Our Services</h1>
        <p className="text-lg mt-2 text-[#1842b6]">
          We provide end-to-end event production services, ensuring that every
          aspect of your event is meticulously planned and executed
        </p>
      </div>
      <div className="max-w-[1400px] mx-auto mt-10 py-4 grid grid-cols-1 md:grid-cols-3 gap-8">
      {servicedata.map((service, index) => (
        <div key={index} className="relative service-cards group transition-all overflow-hidden">
          <div className="absolute top-0 left-0 w-2/4 h-2/4  rounded-xl bg-[#9aca3c] shadow-lg group-hover:opacity-0 transition-opacity duration-400"></div>
          <div className="absolute bottom-0 right-0 w-2/4 h-2/4 rounded-xl bg-[#1842b6] group-hover:opacity-0 transition-opacity duration-400"></div>
          <div className="p-4 relative">
            <img src={service.img} alt={service.title} className="w-[610px] h-[260px] rounded-xl transition-transform duration-200 group-hover:scale-110" />
          </div>
          <h1 className="text-white bg-[#1842b6] p-4 text-center text-xl shadow-lg absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-xl group-hover:opacity-0 transition-opacity duration-300">{service.title}</h1>
          <div className="text-center p-4 bg-black bg-opacity-70  absolute inset-0 rounded-xl flex flex-col justify-center items-center opacity-0 transition-opacity duration-300 group-hover:opacity-100">
            <p className="text-2xl text-[#9aca3c]">{service.title}</p>
            <p className="text-xl text-white">{service.description}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
    </motion.section>
  );
};
export default Services;
