import React from "react";
import {  motion } from "framer-motion";
import BgSvg2 from "./BgSvg2";

const About = () => {
  return (
    <motion.section className="relative min-h-screen flex items-center overflow-hidden" id="about-section">
        <BgSvg2 />
      <div className="relative sm:p-4 max-w-[1240px] mx-auto ">
        <div className=" md:py-8 md:flex gap-20 justify-between items-center">
          <div>
            <div className="blob-1"></div>
          </div>
          <div>
            <h2 className="font-bold text-4xl text-center text-[#1842b6]">About Us</h2>
            <p className="mt-2 text-lg text-center text-gray-500">
              We pride ourselves on being a premier event production company based in the vibrant city of Chennai. Our rich heritage in the event
              industry, combined with a team of seasoned professionals, ensures that we deliver unparalleled event production support, catering to a
              diverse range of events. From intimate gatherings in mini halls to grand concerts that captivate audiences worldwide, Ovation Night
              Events stands at the forefront of the event production landscape.
            </p>
          </div>
        </div>
        <div className="md:flex flex-row-reverse gap-20 justify-between items-center">
          <div>
            <div className="blob-2"></div>
          </div>
          <div>
            <h2 className="font-bold text-4xl text-center text-[#1842b6]">Legacy</h2>
            <p className="mt-2 text-lg text-center text-gray-500">
              Ovation Night Events India Pvt Ltd has become synonymous with excellence in the event production
              industry. Our legacy is built on delivering memorable events that leave a lasting impression on audiences. Whether it’s a local event
              in Chennai or a large-scale international concert, we are committed to creating experiences that are nothing short of legendary.
            </p>
          </div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
