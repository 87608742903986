import React from "react";
import { useScroll, useTransform, motion } from "framer-motion";
import heroImg from '../Assests/hero-img.png';
import BackgroundSvg from "./BackgroundSvg";

const Home = () => {
   const { scrollYProgress } = useScroll();
   const y = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  return (
    <motion.section className="relative h-screen flex items-center overflow-hidden">
      <BackgroundSvg />
      <motion.div style={{ y }} className="absolute inset-0 z-0">
        <div className="hidden relative z-10 md:flex justify-between items-center py-20 w-full">
          <div className="px-20">
            <p className="text-lg">
              The <span className="text-orange-600">ONE Choice</span>, <span className="text-[#1842b6]">ONE Solution</span>
            </p>
            <div className="flex flex-col gap-4 text-9xl font-bold">
              <h1>Ovation</h1>
              <h1>Night</h1>
              <h1>Events</h1>
            </div>
            <p className="text-[#1842b6] text-lg">For Flawless Events and All Your Event Needs</p>
          </div>
          <div>
            <img src={heroImg} alt="" className="h-[600px] w-[600px] mt-20" />
          </div>
        </div>
        <div className="">
        <div className="py-32 flex flex-col text-center justify-center items-center mt-12">
            <p className="text-md font-bold">
              The <span className="text-orange-600">ONE Choice</span>, <span className="text-[#1842b6]">ONE Solution</span>
            </p>
            <div className="flex flex-col gap-2 text-7xl font-bold">
              <h1>Ovation</h1>
              <h1>Night</h1>
              <h1>Events</h1>
            </div>
            <p className="text-[#1842b6] text-md font-bold ">For Flawless Events and <br />All Your Event Needs</p>
          </div>
        </div>
      </motion.div>
      
    </motion.section>
  );
};

export default Home;
