import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { TfiAlignRight, TfiClose } from "react-icons/tfi";
import mainLogo from '../Assests/Logo.png';
import BackgroundSvg from "./BackgroundSvg";

const Header = () => {
  const [nav, setNav] = useState(false);
  const [scrollNav, setScrollNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  const handleScroll = () => {
    if (window.scrollY >= window.innerHeight) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const closeMenu = () => {
    setNav(false);
  };

  return (
    <>
      <nav className={`w-[96%] md:w-[90%] ml-2 md:ml-16 fixed top-3 transition-colors duration-300 rounded-xl  ${scrollNav ? "bg-transparent" : "bg-glass"} z-50`}>
        <div className="flex justify-between items-center p-2">
          <img src={mainLogo} alt="Main Logo" className="w-24 cursor-pointer" />
          <ul className={`font-semibold leading-tight text-[21px] cursor-pointer text-[#1f1f1f] ${scrollNav ? "hidden" : "hidden md:flex"} space-x-4`}>
             <li className="px-2 hover:text-[#1842b6]">
               <Link to="home-section" smooth={true} duration={500}>Home</Link>
             </li>
             <li className="px-2 hover:text-[#1842b6]">
               <Link to="about-section" smooth={true} duration={500}>About Us</Link>
             </li>
             <li className="px-2 hover:text-[#1842b6]">
               <Link to="services-section" smooth={true} duration={500}>Our Services</Link>
             </li>
             <li className="px-2 hover:text-[#1842b6]">
               <Link to="gallery-section" smooth={true} duration={500}>Gallery</Link>
             </li>
            <li className="px-2 hover:text-[#1842b6]">
              <Link to="contact-section" smooth={true} duration={500}>Contact Us</Link>
            </li>
          </ul>
          <div onClick={handleNav} className={`cursor-pointer p-2 font-bold duration-300 z-999 ${scrollNav ? "block" : "block md:hidden"}`}>
            {nav ? <TfiClose size={24} /> : <TfiAlignRight size={24} />}
          </div>
        </div>
      </nav>
      <div className={`fixed top-0 left-0 w-full h-screen bg-white p-6 z-50 transform ${nav ? 'translate-y-0' : '-translate-y-full'} transition-transform duration-500`}>
        <BackgroundSvg/>
        <div className="max-w-[1240px] mx-auto flex justify-end mt-2">
          <div onClick={handleNav} className="cursor-pointer p-2 font-bold z-50">
            <TfiClose size={24} />
          </div>
        </div>
        <ul className="text-4xl flex flex-col items-center cursor-pointer justify-center h-full z-50">
          <li className="py-3 uppercase hover:text-[#1842b6] z-50">
               <Link to="home-section" smooth={true} duration={500} onClick={closeMenu}>Home</Link>
             </li>
             <li className="py-3 uppercase hover:text-[#1842b6] z-50">
               <Link to="about-section" smooth={true} duration={500} onClick={closeMenu}>About Us</Link>
             </li>
             <li className="py-3 uppercase hover:text-[#1842b6] z-50">
               <Link to="services-section" smooth={true} duration={500} onClick={closeMenu}>Our Services</Link>
             </li>
             <li className="py-3 uppercase hover:text-[#1842b6] z-50">
               <Link to="gallery-section" smooth={true} duration={500} onClick={closeMenu}>Gallery</Link>
             </li>
            <li className="py-3 uppercase hover:text-[#1842b6] z-50">
              <Link to="contact-section" smooth={true} duration={500} onClick={closeMenu}>Contact Us</Link>
            </li>
        </ul>
      </div>
    </>
  );
};

export default Header;
